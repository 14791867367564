import http from '@/utils/http';
/**
 * 退出登录
 * params:token
 */
export const logout = params => {
	return http({
		url: '/logout',
		method: 'GET',
		params
	})
}

/**
 * 生成验证码
 */
export const captchaImageApi = params => {
	return http({
		url: '/captchaImage',
		method: 'GET',
		params
	})
}

/**
 * 登录
 */
export const loginApi = data => {
	return http({
		url: '/login',
		method: 'POST',
		data
	})
}

/**
 * 谷歌登录
 */
export const googleLoginApi = (params) => {
	return http({
		url: '/googleLogin',
		method: 'POST',
		params
	})
}


/**
 * 注册
 */
export const registerApi = data => {
	return http({
		url: '/register',
		method: 'POST',
		data
	})
}


/**
 * 获取用户信息
 */
export const getInfoApi = params => {
	return http({
		url: '/getInfo',
		method: 'GET',
		params
	})
}

/**
 * 通过邮箱获取验证码
 */
export const getCodeApi = params => {
	return http({
		url: '/client/mail/getCode',
		method: 'GET',
		params
	})
}

/**
 * 通过邮箱重置密码
 */
export const codeUpdatePwdApi = data => {
	return http({
		url: '/client/mail/codeUpdatePwd',
		method: 'POST',
		data
	})
}



