import { useRouter } from "vue-router"
export default class Button {
  router = undefined;
  constructor(){
    this.router = new useRouter();
  }
  buy(url){
    this.router.push(url);
  }
  scrollerToWhere(cls){
    let el = document.querySelector('.'+cls);
    el.scrollIntoView();
  }
}