let MetaChinese = {
  home:{
    title:"IPRocket – 优质代理提供商和 Web 数据服务",
    description:"通过 IPRocket 访问 80M+ 高质量 IP - 轻松从任何网站提取公共数据！立即享受便宜的价格和优质的服务！",
    h1:"高质量住宅代理，快速且稳定!",
  },
  price:{
    title:"住宅代理服务器按月、即用即付、GB 定价",
    description:"IPRocket 提供 8000 多万个 IP、城市级目标定位和零IP封锁，每 GB 起价仅为 0.9 美元。立即开始免费试用！",
    h1:"住宅代理价格",
  },
  product:{
    title:"优质代理，无与伦比的价格",
    description:"隐藏你的互联网活动而且不会被屏蔽。住宅代理为您提供快速访问和完全匿名服务，价格灵活，有效期长久。",
    h1:"住宅代理",
  },
  contactUS:{
    title:"联系 IPRocket | 您值得信赖的住宅代理供应商",
    description:"立即联系我们的支持团队，利用IPRocket的8000多万个住宅IP代理实现大规模在线运营并拓展业务。",
    h1:"",
  },
  aboutUs:{
    title:"关于我们",
    description:"了解 IPRocket | 您值得信赖的住宅代理提供商",
    h1:"联系IPRocket",
  },
  recommend:{
    title:"加入IPRocket 的推荐计划 | 与我们一起赚钱",
    description:"加入IPRocket的推荐计划，通过推荐客户使用我们的住宅代理服务赚取佣金,获得高额回报、技术支持和营销资源提供。",
    h1:"IPRocket推荐计划",
  },
  treaty:{
    title:"IPRocket | 与 IPRocket 达成独家合作伙伴协议",
    description:"了解 IPRocket 完整的合作伙伴列表，包括指纹浏览器、云手机、账户和虚拟卡，并获得其服务的特别折扣。",
    h1:"欢迎成为IPRocket的合作伙伴"
  },
  professionmPrice:{
    title:"购买代理进行价格监控和跟踪",  
    description:"使用来自全球 195+ 个地点的数钱万个 IP 地址，获取一流的住宅代理服务器",
    h1:"价格监控代理"
  },
  professionmMarket:{
    title:"探索IPRocket代理在市场研究的用例",
    description:"探索 IPRocket 住宅 IP 的市场研究用例。了解我们的代理如何帮助您提升市场研究工作。",
    h1:"市场研究代理"
  },
  professionmAdvertisement:{
    title:"探索 IPRocket 代理的广告验证用例",
    description:"了解 IPRocket 住宅 IP 代理的广告验证用例。了解我们的代理如何为您的广告活动带来益处。",
    h1:"广告验证代理"
  },
  professionmEngine:{
    title:"使用住宅代理改进搜索引擎优化 - IPRocket",
    description:"使用住宅代理提高搜索引擎排名和知名度。IPRocket 可帮助您跟踪关键字、分析竞争对手并避免遇到机器人验证。",
    h1:"搜索引擎优化和搜索结果优化的代理"
  },
  professionmData:{
    title:"探索 IPRocket 代理 IP 的数据抓取使用案例",
    description:"访问数百万个专为数据抓取和数据提取定制的代理服务器。",
    h1:"数据抓取代理"
  },
  professionmTravel:{
    title:"利用住宅代理进行精确的旅行票价汇总",
    description:"IPRocket 是旅行社、航空公司、租赁公司和酒店企业了解市场价格变化的完美工具。",
    h1:"旅行票价汇总代理"
  },
  professionmMotion:{
    title:"使用真实住宅代理抢购运动鞋和门票 - IPRocket",
    description:"使用住宅代理来抢购限量发售的球鞋。IPRocket 是抢鞋的最佳工具，帮助你绕过机器人保护和地理限制。",
    h1:"运动鞋与门票代理"
  },
  professionmEale:{
    title:"利用真实住宅代理促进销售 - IPRocket",
    description:"使用住宅代理提高网站的销售额和转化率。IPRocket 可以帮助您测试和优化网站性能和用户体验。",
    h1:"销售与电子商务代理"
  },
  professionmEmail:{
    title:"探索IPRocket代理IP的电子邮件保护使用案例",
    description:"探索 IPRocket 住宅 IP 代理服务器的电子邮件保护使用案例。了解我们的代理如何保护您的电子邮件通信安全。",
    h1:"电子邮箱保护代理"
  },
  professionmMarketing:{
    title:"使用住宅代理管理社交媒体 - IPRocket",
    description:"使用住宅代理管理多个社交媒体账户，不会被阻止或禁止。IPRocket 可帮助您创建内容并实现内容自动化，与粉丝互动。",
    h1:"社交媒体营销代理"
  },
  mediaChartGPT:{
    title:"最佳ChatGPT代理服务器 - ChatGPT住宅代理IP | IPRocket",
    description:"当您的项目涉及培训ChatGPT模型或创建机器人时，在某些特殊情况下或为特定功能，高性能住宅代理IP池有利于扩展 ChatGPT 的功能",
    h1:"最可靠的ChatGPT代理"
  },
  mediaDiscord:{
    title:"最佳Discord代理服务器 - Discord住宅代理 IP | IPRocket",
    description:"无法从学校访问 Discord？或者被禁言了？现在就试试我们的代理服务，充分利用这个平台吧！",
    h1:"最可靠的Discord代理"
  },
  mediaFacebook:{
    title:"发现最佳、有效的Facebook代理，实现安全浏览",
    description:"你是不是一直在想，世界上是否存在能解封Facebook的工具？我们提供一个完全可用的Facebook代理！",
    h1:"最可靠的Facebook代理"
  },
  mediaInstagram:{
    title:"最佳Instagram代理服务器 - Instagram 住宅代理 IP | IPRocket",
    description:"在 IPRocket 找到最好的Instagram代理！精准定位，纯正海外实居IP代理服务，获取最新商业数据，购买最快的Instagram代理",
    h1:"最可靠的Instagram代理"
  },
  mediaPinterest:{
    title:"用于Pinterest代理服务器的最佳住宅IP代理服务器 | IPRocket",
    description:"利用 IPRocket的住宅IP，有效管理和发展多个Pinterest账户。帮助个人或企业实现内容定制、与特定受众互动并保持独特的在线身份。",
    h1:"最可靠的Pinterest代理"
  },
  mediaTIKTOK:{
    title:"最佳IP代理，帮助您绕过限制，注册多个Tiktok账户管理",
    description:"你是不是一直在想，是否有完全可用的Reddit代理可以用于投票机器人？我们提供最高质量的Reddit代理",
    h1:"最可靠的Tiktok代理"
  },
  mediaReddit:{
    title:"购买顶级且便宜的Reddit住宅代理 - IPRocket",
    description:"体验覆盖190多个地点的本地化网络，通过IPRocket，您可以在全球范围内注册TikTok账户，并接触全球用户。",
    h1:"最可靠的Reddit代理"
  },
  mediaTwitch:{
    title:"IPRocket 为 Twitch Viewer机器人提供最佳代理服务器",
    description:"IPRocket 为您的 Twitch Viewer机器人提供具有额外网络安全性的最佳代理服务器，帮助您在竞争中脱颖而出！",
    h1:"最可靠的Twitch代理"
  },
  mediaYoutube:{
    title:"最佳 Youtube 代理服务器 - Youtube住宅代理 IP | IPRocket",
    description:"在IPRocket找到最好的Youtube代理！准确定位，纯正海外真实住宅IP代理服务，获取最新商业数据，购买最快的Youtube代理服务器",
    h1:"最可靠的Youtube代理"
  },
  airbnb:{
    title:"最佳Airbnb代理服务器 - Airbnb住宅代理 IP | IPRocket",
    description:"精准定位、纯正海外真实住宅 IP 代理服务，获取最新商业数据，购买最快的 Airbnb 代理服务!",
    h1:"最可靠的Airbnb代理"
  },
  amazon:{
    title:"最佳亚马逊代理服务器 - 亚马逊住宅代理IP | IPRocket",
    description:"代理服务器可以通过更改您的 IP 地址来防止封禁。使用IPRocket代理服务器清除亚马逊数据并保护您的真实 IP 安全！立即了解更多！",
    h1:"最可靠的Amazon代理"
  },
  eBey:{
    title:"使用我们最佳、有效的 eBay 代理，实现安全浏览",
    description:"想过如何创建无限量的eBay账户吗？使用eBay代理，您可以无限制地控制、创建和浏览！",
    h1:"最可靠的eBey代理"
  },
  etsy:{
    title:"最佳Etsy代理服务器 - Etsy住宅代理 IP | IPRocket",
    description:"使用IPRocket代理服务器管理多个账户、规避屏蔽以及使用Etsy时的更多可能性！了解更多信息，请访问iprocket.io。",
    h1:"最可靠的Etsy代理"
  },
  mercari:{
    title:"Mercari代理 - 使用 IPRocket 提供安全、实惠的服务",
    description:"IPRocket 是最好的Mercari代理服务器提供商之一！使用代理服务器，您可以从 Mercari 购买多种二手商品而不被发现。",
    h1:"最可靠的Mercari代理"
  },
  shopify:{
    title:"使用我们最佳、有效的 shopify 代理，实现安全浏览",
    description:"IPRocket是Shopify最好的代理服务提供商之一！在我们的网站上阅读更多关于代理类型和使用案例，现在就开始使用吧！",
    h1:"最可靠的Shopify代理"
  },
  vinted:{
    title:"最佳Vinted代理服务器 - Vinted住宅代理 IP | IPRocket",
    description:"由于某些原因，Vinted可能会冻结某个账户，而通过信用卡或借记卡在线支付时，总是存在欺诈风险。使用 IPRocket 代理可以帮助防范这种情况。",
    h1:"最可靠的Vinted代理"
  },
  walmart:{
    title:"最佳Walmart代理服务器 - Walmart住宅代理 IP | IPRocket",
    description:"使用IPRocket代理服务器可以实现更快的购物速度、多个账户和在线身份保护！了解有关沃尔玛代理服务器的更多信息，请访问iprocket.io。",
    h1:"最可靠的Walmart代理"
  },
  countryMore:{
    title:"查看我们支持的所有国家和地区",
    description:"我们为企业和个人用户精心定制套餐，保证为您的所有需求提供完美的解决方案。您可以根据自己的需要选择特定的大洲、地区甚至城市。",
    h1:"所有地点"
  },
  countryBrazil:{
    title:"购买巴西代理服务器，超过110万巴西IP为您服务",
    description:"在 IPRocket 购买巴西代理。查看我们按国家分列的代理服务器可用性，帮助您精准定位全球市场。",
    h1:"最可靠的巴西代理"
  },
  countryVietnam:{
    title:"购买越南代理服务器，超过60万越南IP为您服务",
    description:"在 IPRocket 购买越南代理。查看我们按国家分列的代理服务器可用性，帮助您精准定位全球市场。",
    h1:"最可靠的越南代理"
  },
  countryJapan:{
    title:"购买日本代理服务器，超过40万日本IP为您服务 ",
    description:"在 IPRocket 购买日本代理。查看我们按国家分列的代理服务器可用性，帮助您精准定位全球市场。",
    h1:"最可靠的日本代理"
  },
  countryGermany:{
    title:"购买德国代理服务器，超过70万德国IP为您服务",
    description:"在 IPRocket 购买德国代理。查看我们按国家分列的代理服务器可用性，帮助您精准定位全球市场。",
    h1:"最可靠的德国代理"
  },
  countryCanada:{
    title:"购买加拿大代理服务器，超过50万加拿大IP为您服务",
    description:"在 IPRocket 购买加拿大代理。查看我们按国家分列的代理服务器可用性，帮助您精准定位全球市场。",
    h1:"最可靠的加拿大代理"
  },
  countryPhilippines:{
    title:"购买菲律宾代理服务器，超过60万菲律宾IP为您服务",
    description:"在 IPRocket 购买菲律宾代理。查看我们按国家分列的代理服务器可用性，帮助您精准定位全球市场。",
    h1:"最可靠的菲律宾代理"
  },
  countryHongkong:{
    title:"购买香港代理服务器，超过50万香港IP为您服务",
    description:"在 IPRocket 购买香港代理。查看我们按国家分列的代理服务器可用性，帮助您精准定位全球市场。",
    h1:""
  },
  countryBritain:{
    title:"购买英国代理服务器，超过110万英国IP为您服务",
    description:"在 IPRocket 购买英国代理。查看我们按国家分列的代理服务器可用性，帮助您精准定位全球市场。",
    h1:"最可靠的英国代理"
  },
  countryAmerica:{
    title:"购买美国代理服务器，超过300万美国IP为您服务",
    description:"在 IPRocket 购买美国代理。查看我们按国家分列的代理服务器可用性，帮助您精准定位全球市场。",
    h1:"最可靠的美国代理"
  },
  countryFrance:{
    title:"购买法国代理服务器，超过110万法国IP为您服务",
    description:"在 IPRocket 购买法国代理。查看我们按国家分列的代理服务器可用性，帮助您精准定位全球市场。",
    h1:"最可靠的法国代理"
  },
  partnership:{
    title:"IPRocket | 与 IPRocket 达成独家合作伙伴协议",
    description:"了解 IPRocket 完整的合作伙伴列表，包括指纹浏览器、云手机、账户和虚拟卡，并获得其服务的特别折扣。",
    h1:"欢迎成为IPRocket的合作伙伴"
  },
}

export default MetaChinese;