import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { createMetaManager } from 'vue-meta';

AOS.init({
  duration: 1000,
  easing: 'ease-in-out-back'
});

createApp(App)
  .use(store)
  .use(router)
  .use(
    createMetaManager(false, {
      description: { tag: 'meta', nameless: true },
      title: { tag: 'meta', nameless: true }
    })
  )
  .use(ElementPlus)
  .mount('#app');
