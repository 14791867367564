import { createRouter, RouteRecordRaw, createWebHashHistory } from 'vue-router';
import { nextTick } from 'vue';
import MataChinsese from '@/assets/json/chineseMeta.js';
import MetaEnglish from '@/assets/json/englishMeta.js';
const lang = localStorage.getItem('langrage');
const routes: Array<RouteRecordRaw> = [
  {
    path: '/treaty/:type',
    name: 'treaty',
    redirect: '/redirect/:type',
    children: [
      {
        path: '/treaty/:type',
        name: 'treaty',
        component: () => import(/* webpackChunkName: "treaty" */ '@/views/treaty.vue')
      },
      {
        path: '/zh/treaty/:type',
        name: 'zh/treaty',
        component: () => import(/* webpackChunkName: "treaty" */ '@/views/treaty.vue')
      }
    ]
  },
  {
    path: '/recommend',
    name: 'recommend',
    redirect: '/referral-program/',
    children: [
      {
        path: '/referral-program/',
        name: 'referral-program/',
        component: () => import(/* webpackChunkName: "recommend" */ '@/views/recommend.vue')
      },
      {
        path: '/zh/referral-program/',
        name: 'zh/referral-program/',
        component: () => import(/* webpackChunkName: "recommend" */ '@/views/recommend.vue')
      }
    ]
  },
  {
    path: '/contactUS',
    name: 'contactUS',
    redirect: '/contact-us',
    children: [
      {
        path: '/contact-us',
        name: 'contact-us',
        component: () => import(/* webpackChunkName: "contactUS" */ '@/views/contactUS.vue')
      },
      {
        path: '/zh/contact-us',
        name: 'zh/contact-us',
        component: () => import(/* webpackChunkName: "contactUS" */ '@/views/contactUS.vue')
      }
    ]
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    redirect: '/about-us',
    children: [
      {
        path: '/about-us',
        name: 'about-us',
        component: () => import(/* webpackChunkName: "aboutUs" */ '@/views/aboutUs.vue')
      },
      {
        path: '/zh/about-us',
        name: 'zh/about-us',
        component: () => import(/* webpackChunkName: "aboutUs" */ '@/views/aboutUs.vue')
      }
    ]
  },
  {
    path: '/partnership',
    name: 'partnership',
    redirect: '/partners/',
    children: [
      {
        path: '/partners/',
        name: 'partners/',
        component: () => import(/* webpackChunkName: "partnership" */ '@/views/partnership.vue')
      },
      {
        path: '/zh/partners/',
        name: 'zh/partners/',
        component: () => import(/* webpackChunkName: "partnership" */ '@/views/partnership.vue')
      }
    ]
  },
  {
    path: '/countryFrance',
    name: 'countryFrance',
    redirect: '/locations/france',
    children: [
      {
        path: '/locations/france',
        name: 'locations/france',
        component: () => import(/* webpackChunkName: "countryFrance" */ '@/views/countryFrance.vue')
      },
      {
        path: '/zh/locations/france',
        name: 'zh/locations/france',
        component: () => import(/* webpackChunkName: "countryFrance" */ '@/views/countryFrance.vue')
      }
    ]
  },
  {
    path: '/countryAmerica',
    name: 'countryAmerica',
    redirect: '/locations/united-state',
    children: [
      {
        path: '/locations/united-state',
        name: 'locations/united-state',
        component: () => import(/* webpackChunkName: "countryAmerica" */ '@/views/countryAmerica.vue')
      },
      {
        path: '/zh/locations/united-state',
        name: 'zh/locations/united-state',
        component: () => import(/* webpackChunkName: "countryAmerica" */ '@/views/countryAmerica.vue')
      }
    ]
  },
  {
    path: '/countryBritain',
    name: 'countryBritain',
    redirect: '/locations/united-kindom',
    children: [
      {
        path: '/locations/united-kindom',
        name: 'locations/united-kindom',
        component: () => import(/* webpackChunkName: "countryBritain" */ '@/views/countryBritain.vue')
      },
      {
        path: '/zh/locations/united-kindom',
        name: 'zh/locations/united-kindom',
        component: () => import(/* webpackChunkName: "countryBritain" */ '@/views/countryBritain.vue')
      }
    ]
  },
  {
    path: '/countryHongkong',
    name: 'countryHongkong',
    redirect: '/locations/hongkong',
    children: [
      {
        path: '/locations/hongkong',
        name: 'locations/hongkong',
        component: () => import(/* webpackChunkName: "countryHongkong" */ '@/views/countryHongkong.vue')
      },
      {
        path: '/zh/locations/hongkong',
        name: 'zh/locations/hongkong',
        component: () => import(/* webpackChunkName: "countryHongkong" */ '@/views/countryHongkong.vue')
      }
    ]
  },
  {
    path: '/countryPhilippines',
    name: 'countryPhilippines',
    redirect: '/locations/philippine',
    children: [
      {
        path: '/locations/philippine',
        name: 'locations/philippine',
        component: () => import(/* webpackChunkName: "countryPhilippines" */ '@/views/countryPhilippines.vue')
      },
      {
        path: '/zh/locations/philippine',
        name: 'zh/locations/philippine',
        component: () => import(/* webpackChunkName: "countryPhilippines" */ '@/views/countryPhilippines.vue')
      }
    ]
  },
  {
    path: '/countryCanada',
    name: 'countryCanada',
    redirect: '/locations/canada',
    children: [
      {
        path: '/locations/canada',
        name: 'locations/canada',
        component: () => import(/* webpackChunkName: "countryCanada" */ '@/views/countryCanada.vue')
      },
      {
        path: '/zh/locations/canada',
        name: 'zh/locations/canada',
        component: () => import(/* webpackChunkName: "countryCanada" */ '@/views/countryCanada.vue')
      }
    ]
  },
  {
    path: '/countryGermany',
    name: 'countryGermany',
    redirect: '/locations/germany',
    children: [
      {
        path: '/locations/germany',
        name: 'locations/germany',
        component: () => import(/* webpackChunkName: "countryGermany" */ '@/views/countryGermany.vue')
      },
      {
        path: '/zh/locations/germany',
        name: 'zh/locations/germany',
        component: () => import(/* webpackChunkName: "countryGermany" */ '@/views/countryGermany.vue')
      }
    ]
  },
  {
    path: '/countryJapan',
    name: 'countryJapan',
    redirect: '/locations/japan',
    children: [
      {
        path: '/locations/japan',
        name: 'locations/japan',
        component: () => import(/* webpackChunkName: "countryJapan" */ '@/views/countryJapen.vue')
      },
      {
        path: '/zh/locations/japan',
        name: 'zh/locations/japan',
        component: () => import(/* webpackChunkName: "countryJapan" */ '@/views/countryJapen.vue')
      }
    ]
  },
  {
    path: '/countryVietnam',
    name: 'countryVietnam',
    redirect: '/locations/vietnam',
    children: [
      {
        path: '/locations/vietnam',
        name: 'locations/vietnam',
        component: () => import(/* webpackChunkName: "countryVietnam" */ '@/views/countryVietnam.vue')
      },
      {
        path: '/zh/locations/vietnam',
        name: 'zh/locations/vietnam',
        component: () => import(/* webpackChunkName: "countryVietnam" */ '@/views/countryVietnam.vue')
      }
    ]
  },
  {
    path: '/countryBrazil',
    name: 'countryBrazil',
    redirect: '/locations/brazil',
    children: [
      {
        path: '/locations/brazil',
        name: 'locations/brazil',
        component: () => import(/* webpackChunkName: "countryBrazil" */ '@/views/countryBrazil.vue')
      },
      {
        path: '/zh/locations/brazil',
        name: 'zh/locations/brazil',
        component: () => import(/* webpackChunkName: "countryBrazil" */ '@/views/countryBrazil.vue')
      }
    ]
  },
  {
    path: '/countryMore',
    name: 'countryMore',
    redirect: '/locations/more',
    children: [
      {
        path: '/locations/more',
        name: 'locations/more',
        component: () => import(/* webpackChunkName: "countryMore" */ '@/views/countryMore.vue')
      },
      {
        path: '/zh/locations/more',
        name: 'zh/locations/more',
        component: () => import(/* webpackChunkName: "countryMore" */ '@/views/countryMore.vue')
      }
    ]
  },
  {
    path: '/home',
    name: 'home',
    redirect: '/',
    children: [
      {
        path: '/',
        component: () => import(/* webpackChunkName: "HomeView" */ '@/views/HomeView.vue')
      },
      {
        path: '/zh',
        component: () => import(/* webpackChunkName: "HomeView" */ '@/views/HomeView.vue')
      }
    ]
  },
  {
    path: '/product',
    name: 'product',
    redirect: '/products/residential-proxies/',
    children: [
      {
        path: '/products/residential-proxies/',
        name: 'products/residential-proxies/',
        component: () => import(/* webpackChunkName: "product" */ '@/views/product.vue')
      },
      {
        path: '/zh/products/residential-proxies/',
        name: 'zh/products/residential-proxies/',
        component: () => import(/* webpackChunkName: "product" */ '@/views/product.vue')
      },
      {
        path: '/products/premium-residential-proxies/',
        name: 'products/premium-residential-proxies/',
        component: () => import(/* webpackChunkName: "product" */ '@/views/supProduct.vue')
      },
      {
        path: '/zh/products/premium-residential-proxies/',
        name: 'zh/products/premium-residential-proxies/',
        component: () => import(/* webpackChunkName: "product" */ '@/views/supProduct.vue')
      }
    ]
  },
  {
    path: '/price',
    name: 'price',
    redirect: '/prices/residential-proxies-pricing/',
    children: [
      {
        path: '/prices/residential-proxies-pricing/',
        name: 'prices/residential-proxies-pricing/',
        component: () => import(/* webpackChunkName: "price" */ '@/views/price.vue')
      },
      {
        path: '/zh/prices/residential-proxies-pricing/',
        name: 'zh/prices/residential-proxies-pricing/',
        component: () => import(/* webpackChunkName: "price" */ '@/views/price.vue')
      },
      {
        path: '/prices/premium-residential-proxies-pricing/',
        name: 'prices/premium-residential-proxies-pricing/',
        component: () => import(/* webpackChunkName: "price" */ '@/views/supPrice.vue')
      },
      {
        path: '/zh/prices/premium-residential-proxies-pricing/',
        name: 'zh/prices/premium-residential-proxies-pricing/',
        component: () => import(/* webpackChunkName: "price" */ '@/views/supPrice.vue')
      }
    ]
  },
  {
    path: '/professionmPrice',
    name: 'professionmPrice',
    redirect: '/use-cases/price-monitoring',
    children: [
      {
        path: '/use-cases/price-monitoring',
        name: 'use-cases/price-monitoring',
        component: () => import(/* webpackChunkName: "professionmPrice" */ '@/views/professionmPrice.vue')
      },
      {
        path: '/zh/use-cases/price-monitoring',
        name: 'zh/use-cases/price-monitoring',
        component: () => import(/* webpackChunkName: "professionmPrice" */ '@/views/professionmPrice.vue')
      }
    ]
  },
  {
    path: '/professionmMarket',
    name: 'professionmMarket',
    redirect: '/use-cases/market-research',
    children: [
      {
        path: '/use-cases/market-research',
        name: 'use-cases/market-research',
        component: () => import(/* webpackChunkName: "professionmMarket" */ '@/views/professionmMarket.vue')
      },
      {
        path: '/zh/use-cases/market-research',
        name: 'zh/use-cases/market-research',
        component: () => import(/* webpackChunkName: "professionmMarket" */ '@/views/professionmMarket.vue')
      }
    ]
  },
  {
    path: '/professionmAdvertisement',
    name: 'professionmAdvertisement',
    redirect: '/use-cases/ad-verificatgion',
    children: [
      {
        path: '/use-cases/ad-verificatgion',
        name: 'use-cases/ad-verificatgion',
        component: () =>
          import(/* webpackChunkName: "professionmAdvertisement" */ '@/views/professionmAdvertisement.vue')
      },
      {
        path: '/zh/use-cases/ad-verificatgion',
        name: 'zh/use-cases/ad-verificatgion',
        component: () =>
          import(/* webpackChunkName: "professionmAdvertisement" */ '@/views/professionmAdvertisement.vue')
      }
    ]
  },
  {
    path: '/professionmEngine',
    name: 'professionmEngine',
    redirect: '/use-cases/seo',
    children: [
      {
        path: '/use-cases/seo',
        name: 'use-cases/seo',
        component: () => import(/* webpackChunkName: "professionmEngine" */ '@/views/professionmEngine.vue')
      },
      {
        path: '/zh/use-cases/seo',
        name: 'zh/use-cases/seo',
        component: () => import(/* webpackChunkName: "professionmEngine" */ '@/views/professionmEngine.vue')
      }
    ]
  },
  {
    path: '/professionmData',
    name: 'professionmData',
    redirect: '/use-cases/data-collection',
    children: [
      {
        path: '/use-cases/data-collection',
        name: 'use-cases/data-collection',
        component: () => import(/* webpackChunkName: "professionmData" */ '@/views/professionmData.vue')
      },
      {
        path: '/zh/use-cases/data-collection',
        name: 'zh/use-cases/data-collection',
        component: () => import(/* webpackChunkName: "professionmData" */ '@/views/professionmData.vue')
      }
    ]
  },
  {
    path: '/professionmTravel',
    name: 'professionmTravel',
    redirect: '/use-cases/travel-fare-aggregation',
    children: [
      {
        path: '/use-cases/travel-fare-aggregation',
        name: 'use-cases/travel-fare-aggregation',
        component: () => import(/* webpackChunkName: "professionmTravel" */ '@/views/professionmTravel.vue')
      },
      {
        path: '/zh/use-cases/travel-fare-aggregation',
        name: 'zh/use-cases/travel-fare-aggregation',
        component: () => import(/* webpackChunkName: "professionmTravel" */ '@/views/professionmTravel.vue')
      }
    ]
  },
  {
    path: '/professionmMotion',
    name: 'professionmMotion',
    redirect: '/use-cases/sneaker',
    children: [
      {
        path: '/use-cases/sneaker',
        name: 'use-cases/sneaker',
        component: () => import(/* webpackChunkName: "professionmMotion" */ '@/views/professionmMotion.vue')
      },
      {
        path: '/zh/use-cases/sneaker',
        name: 'zh/use-cases/sneaker',
        component: () => import(/* webpackChunkName: "professionmMotion" */ '@/views/professionmMotion.vue')
      }
    ]
  },
  {
    path: '/professionmEale',
    name: 'professionmEale',
    redirect: '/use-cases/selling',
    children: [
      {
        path: '/use-cases/selling',
        name: 'use-cases/selling',
        component: () => import(/* webpackChunkName: "professionmEale" */ '@/views/professionmEale.vue')
      },
      {
        path: '/zh/use-cases/selling',
        name: 'zh/use-cases/selling',
        component: () => import(/* webpackChunkName: "professionmEale" */ '@/views/professionmEale.vue')
      }
    ]
  },
  {
    path: '/professionmEmail',
    name: 'professionmEmail',
    redirect: '/use-cases/email-protection',
    children: [
      {
        path: '/use-cases/email-protection',
        name: 'use-cases/email-protection',
        component: () => import(/* webpackChunkName: "professionmEmail" */ '@/views/professionmEmail.vue')
      },
      {
        path: '/zh/use-cases/email-protection',
        name: 'zh/use-cases/email-protection',
        component: () => import(/* webpackChunkName: "professionmEmail" */ '@/views/professionmEmail.vue')
      }
    ]
  },
  {
    path: '/professionmMarketing',
    name: 'professionmMarketing',
    redirect: '/use-cases/social-media',
    children: [
      {
        path: '/use-cases/social-media',
        name: 'use-cases/social-media',
        component: () => import(/* webpackChunkName: "professionmMarketing" */ '@/views/professionmMarketing.vue')
      },
      {
        path: '/zh/use-cases/social-media',
        name: 'zh/use-cases/social-media',
        component: () => import(/* webpackChunkName: "professionmMarketing" */ '@/views/professionmMarketing.vue')
      }
    ]
  },
  {
    path: '/mediaChartGPT',
    name: 'mediaChartGPT',
    redirect: '/social-media/chatgpt',
    children: [
      {
        path: '/social-media/chatgpt',
        name: 'social-media/chatgpt',
        component: () => import(/* webpackChunkName: "mediaChartGPT" */ '@/views/mediaChartGPT.vue')
      },
      {
        path: '/zh/social-media/chatgpt',
        name: 'zh/social-media/chatgpt',
        component: () => import(/* webpackChunkName: "mediaChartGPT" */ '@/views/mediaChartGPT.vue')
      }
    ]
  },
  {
    path: '/mediaDiscord',
    name: 'mediaDiscord',
    component: () => import(/* webpackChunkName: "mediaDiscord" */ '@/views/mediaDiscord.vue'),
    redirect: '/social-media/discord',
    children: [
      {
        path: '/social-media/discord',
        name: 'social-media/discord',
        component: () => import(/* webpackChunkName: "mediaChartGPT" */ '@/views/mediaChartGPT.vue')
      },
      {
        path: '/zh/social-media/discord',
        name: 'zh/social-media/discord',
        component: () => import(/* webpackChunkName: "mediaChartGPT" */ '@/views/mediaChartGPT.vue')
      }
    ]
  },
  {
    path: '/mediaFacebook',
    name: 'mediaFacebook',
    redirect: '/social-media/facebook',
    children: [
      {
        path: '/social-media/facebook',
        name: 'social-media/facebook',
        component: () => import(/* webpackChunkName: "mediaFacebook" */ '@/views/mediaFacebook.vue')
      },
      {
        path: '/zh/social-media/facebook',
        name: 'zh/social-media/facebook',
        component: () => import(/* webpackChunkName: "mediaFacebook" */ '@/views/mediaFacebook.vue')
      }
    ]
  },
  {
    path: '/mediaInstagram',
    name: 'mediaInstagram',
    redirect: '/social-media/instagram',
    children: [
      {
        path: '/social-media/instagram',
        name: 'social-media/instagram',
        component: () => import(/* webpackChunkName: "mediaInstagram" */ '@/views/mediaInstagram.vue')
      },
      {
        path: '/zh/social-media/instagram',
        name: 'zh/social-media/instagram',
        component: () => import(/* webpackChunkName: "mediaInstagram" */ '@/views/mediaInstagram.vue')
      }
    ]
  },
  {
    path: '/mediaPinterest',
    name: 'mediaPinterest',
    redirect: '/social-media/pinterest',
    children: [
      {
        path: '/social-media/pinterest',
        name: 'social-media/pinterest',
        component: () => import(/* webpackChunkName: "mediaPinterest" */ '@/views/mediaPinterest.vue')
      },
      {
        path: '/zh/social-media/pinterest',
        name: 'zh/social-media/pinterest',
        component: () => import(/* webpackChunkName: "mediaPinterest" */ '@/views/mediaPinterest.vue')
      }
    ]
  },
  {
    path: '/mediaReddit',
    name: 'mediaReddit',
    redirect: '/social-media/reddit',
    children: [
      {
        path: '/social-media/reddit',
        name: 'social-media/reddit',
        component: () => import(/* webpackChunkName: "mediaReddit" */ '@/views/mediaReddit.vue')
      },
      {
        path: '/zh/social-media/reddit',
        name: 'zh/social-media/reddit',
        component: () => import(/* webpackChunkName: "mediaReddit" */ '@/views/mediaReddit.vue')
      }
    ]
  },
  {
    path: '/mediaTIKTOK',
    name: 'mediaTIKTOK',
    redirect: '/social-media/tiktok',
    children: [
      {
        path: '/social-media/tiktok',
        name: 'social-media/tiktok',
        component: () => import(/* webpackChunkName: "mediaTIKTOK" */ '@/views/mediaTIKTOK.vue')
      },
      {
        path: '/zh/social-media/tiktok',
        name: 'zh/social-media/tiktok',
        component: () => import(/* webpackChunkName: "mediaTIKTOK" */ '@/views/mediaTIKTOK.vue')
      }
    ]
  },
  {
    path: '/mediaTwitch',
    name: 'mediaTwitch',
    redirect: '/social-media/twitch',
    children: [
      {
        path: '/social-media/twitch',
        name: 'social-media/twitch',
        component: () => import(/* webpackChunkName: "mediaTwitch" */ '@/views/mediaTwitch.vue')
      },
      {
        path: '/zh/social-media/twitch',
        name: 'zh/social-media/twitch',
        component: () => import(/* webpackChunkName: "mediaTwitch" */ '@/views/mediaTwitch.vue')
      }
    ]
  },
  {
    path: '/mediaYoutube',
    name: 'mediaYoutube',
    redirect: '/social-media/youtube',
    children: [
      {
        path: '/social-media/youtube',
        name: 'social-media/youtube',
        component: () => import(/* webpackChunkName: "mediaYoutube" */ '@/views/mediaYoutube.vue')
      },
      {
        path: '/zh/social-media/youtube',
        name: 'zh/social-media/youtube',
        component: () => import(/* webpackChunkName: "mediaYoutube" */ '@/views/mediaYoutube.vue')
      }
    ]
  },
  {
    path: '/airbnb',
    name: 'airbnb',
    redirect: '/ecommerce/airbnb',
    children: [
      {
        path: '/ecommerce/airbnb',
        name: 'ecommerce/airbnb',
        component: () => import(/* webpackChunkName: "businessOnlineAirbnb" */ '@/views/businessOnlineAirbnb.vue')
      },
      {
        path: '/zh/ecommerce/airbnb',
        name: 'zh/ecommerce/airbnb',
        component: () => import(/* webpackChunkName: "businessOnlineAirbnb" */ '@/views/businessOnlineAirbnb.vue')
      }
    ]
  },
  {
    path: '/amazon',
    name: 'amazon',
    redirect: '/ecommerce/amazon',
    children: [
      {
        path: '/ecommerce/amazon',
        name: 'ecommerce/amazon',
        component: () => import(/* webpackChunkName: "businessOnlineAmazon" */ '@/views/businessOnlineAmazon.vue')
      },
      {
        path: '/zh/ecommerce/amazon',
        name: 'zh/ecommerce/amazon',
        component: () => import(/* webpackChunkName: "businessOnlineAmazon" */ '@/views/businessOnlineAmazon.vue')
      }
    ]
  },
  {
    path: '/eBey',
    name: 'eBey',
    redirect: '/ecommerce/ebay',
    children: [
      {
        path: '/ecommerce/ebay',
        name: 'ecommerce/ebay',
        component: () => import(/* webpackChunkName: "businessOnlineEBey" */ '@/views/businessOnlineEBey.vue')
      },
      {
        path: '/zh/ecommerce/ebay',
        name: 'zh/ecommerce/ebay',
        component: () => import(/* webpackChunkName: "businessOnlineEBey" */ '@/views/businessOnlineEBey.vue')
      }
    ]
  },
  {
    path: '/etsy',
    name: 'etsy',
    redirect: '/ecommerce/etsy',
    children: [
      {
        path: '/ecommerce/etsy',
        name: 'ecommerce/etsy',
        component: () => import(/* webpackChunkName: "businessOnlineEtsy" */ '@/views/businessOnlineEtsy.vue')
      },
      {
        path: '/zh/ecommerce/etsy',
        name: 'zh/ecommerce/etsy',
        component: () => import(/* webpackChunkName: "businessOnlineEtsy" */ '@/views/businessOnlineEtsy.vue')
      }
    ]
  },
  {
    path: '/mercari',
    name: 'mercari',
    redirect: '/ecommerce/mercari',
    children: [
      {
        path: '/ecommerce/mercari',
        name: 'ecommerce/mercari',
        component: () => import(/* webpackChunkName: "businessOnlineMercari" */ '@/views/businessOnlineMercari.vue')
      },
      {
        path: '/zh/ecommerce/mercari',
        name: 'zh/ecommerce/mercari',
        component: () => import(/* webpackChunkName: "businessOnlineMercari" */ '@/views/businessOnlineMercari.vue')
      }
    ]
  },
  {
    path: '/shopify',
    name: 'shopify',
    redirect: '/ecommerce/shopify',
    children: [
      {
        path: '/ecommerce/shopify',
        name: 'ecommerce/shopify',
        component: () => import(/* webpackChunkName: "businessOnlineShopify" */ '@/views/businessOnlineShopify.vue')
      },
      {
        path: '/zh/ecommerce/shopify',
        name: 'zh/ecommerce/shopify',
        component: () => import(/* webpackChunkName: "businessOnlineShopify" */ '@/views/businessOnlineShopify.vue')
      }
    ]
  },
  {
    path: '/vinted',
    name: 'vinted',
    redirect: '/ecommerce/vinted',
    children: [
      {
        path: '/ecommerce/vinted',
        name: 'ecommerce/vinted',
        component: () => import(/* webpackChunkName: "businessOnlineVinted" */ '@/views/businessOnlineVinted.vue')
      },
      {
        path: '/zh/ecommerce/vinted',
        name: 'zh/ecommerce/vinted',
        component: () => import(/* webpackChunkName: "businessOnlineVinted" */ '@/views/businessOnlineVinted.vue')
      }
    ]
  },
  {
    path: '/walmart',
    name: 'walmart',
    redirect: '/ecommerce/walmart',
    children: [
      {
        path: '/ecommerce/walmart',
        name: 'ecommerce/walmart',
        component: () => import(/* webpackChunkName: "businessOnlineWalmart" */ '@/views/businessOnlineWalmart.vue')
      },
      {
        path: '/zh/ecommerce/walmart',
        name: 'zh/ecommerce/walmart',
        component: () => import(/* webpackChunkName: "businessOnlineWalmart" */ '@/views/businessOnlineWalmart.vue')
      }
    ]
  }
];
routes.forEach((route) => {
  let lang = localStorage.getItem('langrage');
  if (lang !== 'english' && route['redirect']) {
    let rs = (route['redirect'] as string).substring(1);
    route['redirect'] = '/zh/' + rs;
  }
});
console.log(routes);
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  let elements = document.getElementsByClassName('anim');
  for (let i = 0; i < elements.length; i++) {
    elements[i].classList.remove('toTop');
  }
  window.scrollTo(0, 0);
  nextTick(() => {
    next();
  });
});

router.isReady().then(() => {});

export default router;
