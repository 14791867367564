let MetaEnglish = {
  home: {
    title: 'IPRocket – Premium Proxy Provider & Service to Web Data',
    description:
      'Access 80M+ high quality IP via IPRocket - easily extract public data from any website! Enjoy cheap prices and quality service immediately!',
    h1: 'High-quality residential proxy, fast and stable！	'
  },
  price: {
    title: 'Residential Proxies Monthly, Pay As You Go, GB Pricing',
    description:
      'Dive into a world of fast residential IP proxies with IPRocket – offering 80M+ IPs, city-level targeting, and zero IP blocks, starting from just $0.9 per GB. Start your free trial today',
    h1: 'Residential Proxies Pricing'
  },
  product: {
    title: 'IPRocket | Premium Quality Proxies, Unbeatable Prices',
    description:
      'Hide your internet activity without getting blocked. Residential proxies give you fast access and full anonymity—with flexible pricing that never expires.',
    h1: 'Residential Proxies'
  },
  contactUS: {
    title: 'Contact IPRocket | Your Trusted Residential Proxy Provider',
    description:
      "Contact our support team today to leverage IPRocket's over 80 million residential IP proxies to achieve massive online operations and expand your business.",
    h1: 'Contact IPRocket'
  },
  aboutUs: {
    title: 'Learn About IPRocket | Your Trusted Residential Proxy Provider',
    description:
      'At IPRocket, we aim to provide a sustainable and predictable solution tailored to your specific IP address business needs. Our solutions can give you a first-rate experience that grows along with you.',
    h1: 'About IPRocket'
  },
  recommend: {
    title: "Join IPRocket's Referral Program | Earn with Us",
    description:
      "Join the IPRocket's referral program to earn commissions by referring customers to our residential proxy service. Great payouts, support, marketing assets provided.",
    h1: 'IPRocket Referral Program'
  },
  treaty: {
    title: 'IPRocket | Get Exclusive Partner Deals with IPRocket',
    description:
      'Discover IPRocket full list of partners, including anti-detection browsers, Cloudphone, accounts and virtual cards, and get special discounts on their services.',
    h1: "Welcome to be IPRocket's partners"
  },
  professionmPrice: {
    title: 'Buy Proxies for Price Monitoring & Tracking',
    description:
      'Get top-notch residential proxies with millions of IP addresses from 190+ locations all over the world',
    h1: 'Proxies for Price Monitoring'
  },
  professionmMarket: {
    title: 'Explore Market Research Use Cases for IPRocket proxy IPs',
    description:
      'Explore the market research use cases for IPRocket residential IPs. Find out how our proxies can help boost your market research efforts.',
    h1: 'Proxies for Market Ressarch'
  },
  professionmAdvertisement: {
    title: 'Explore Ad Verificatgion Use Cases for IPRocket Proxies',
    description:
      'Discover the ad verificatgion use cases for IPRocket residential IP proxies. Learn how our proxies can benefit your advertising campaigns.',
    h1: 'Proxies for Ad Verificatgion'
  },
  professionmEngine: {
    title: 'Improve SEO with Residential Proxy - IPRocket',
    description:
      'Use residential proxies to improve search engine rankings and visibility. IPRocket can help you track keywords, analyze competitors and avoid CAPTCHAs.',
    h1: 'Proxies for SEO and SERP'
  },
  professionmData: {
    title: 'Explore Data Scraping Use Cases for IPRocket proxy IPs',
    description: 'Get access to millions of proxies tailor-made for data scraping and data extraction.',
    h1: 'Proxies for Data Collection'
  },
  professionmTravel: {
    title: 'Accurate Travel Fare Aggregation with Residential Proxies',
    description:
      'IPRocket are perfect for travel agents, airlines, rental companies, and hospitality businesses to stay on top of any price changes in the market.',
    h1: 'Proxies for Travel Fare Aggregation'
  },
  professionmMotion: {
    title: 'Sneakers and tickets with Real Residential Proxies - IPRocket',
    description:
      'Use residential proxies to cop sneakers from limited releases and drops. IPRocket are the best tool for sneaker copping. Help you bypass bot protection, geo-restrictions.',
    h1: 'Proxies for Sneaker and Ticket'
  },
  professionmEale: {
    title: 'Boost Your Sales with Real Residential Proxies - IPRocket',
    description:
      'Use Residential Proxy to increase sales and conversions on your website. IPRocket can help you test and optimize your website performance and user experience.',
    h1: 'Proxies for Selling & E-commerce'
  },
  professionmEmail: {
    title: 'Explore Email Protection Use Cases for IPRocket proxy IPs',
    description:
      'Explore the email protection use cases for IPRocket residential IP proxies. Find out how our proxies can help secure your email communications.',
    h1: 'Proxies for Email Protection'
  },
  professionmMarketing: {
    title: 'Managing Social Media with Residential Proxy - IPRocket',
    description:
      'Manage multiple social media accounts without being blocked or banned with Residential Proxy. IPRocket can help you create and automate content, engage with followers.',
    h1: 'Proxies for Social Media'
  },
  mediaChartGPT: {
    title: 'Best ChatGPT Proxies Provider – ChatGPT Residential Proxy IP | IPRocket',
    description:
      "When your project involves training ChatGPT models or creating bots, in some special cases or for specific functionality, the High-performance residential proxy IP pools are beneficial to extend ChatGPT's functionality",
    h1: 'The most reliable ChatGPT Proxies'
  },
  mediaDiscord: {
    title: 'Best Discord Proxies Provider – Discord Residential Proxy IP | IPRocket',
    description:
      'Do not have access to Discord from your school? Or maybe got a ban? Try our services and use the platform to the fullest now!',
    h1: 'The most reliable Discord Proxies'
  },
  mediaFacebook: {
    title: 'Discover Best, Working Facebook Proxy For Safe Browsing',
    description:
      'Always Wondered If There Is In This World Working Facebook Unblocker? We Present Fully Working Proxy For Facebook!',
    h1: 'The most reliable Facebook Proxies'
  },
  mediaInstagram: {
    title: 'Best Instagram Proxies Provider – Instagram Residential Proxy IP | IPRocket',
    description:
      'Find the best Instagram proxy at IPRocket! Accurately positioned and pure overseas real residential IP proxy service, obtain the latest business data, and purchase the fastest Instagram proxy！',
    h1: 'The most reliable Instagram Proxies'
  },
  mediaPinterest: {
    title: 'Best Residential IP Proxies for Pinterest Proxies | IPRocket',
    description:
      "Efficiently manage and grow multiple Pinterest accounts with IPRocket's Residential IP. Help individuals or businesses achieve the ability to customize their content, interact with specific audiences and maintain a unique online identity.",
    h1: 'The most reliable Pinterest Proxies'
  },
  mediaTIKTOK: {
    title: 'Best IP Proxy, Helps you bypass limits, sign up for multiple Tiktok account management',
    description:
      'Experience a localized network of 190+ locations with IPRocket, you can register Tik Tok account globally and get close to global users!',
    h1: 'The most reliable Tiktok Proxies'
  },
  mediaReddit: {
    title: 'Buy Top Cheap Residential Reddit Proxy Servers - IPRocket',
    description:
      'Always Wondered If There Is Fully Working Reddit Proxy for Upvote bots?We Present Highest Quality Proxy For Reddit!',
    h1: 'The most reliable Reddit Proxies'
  },
  mediaTwitch: {
    title: 'IPRocket provides the Best Proxy for Twitch Viewer Robots',
    description:
      'IPRocket helps you stand out from the competition by providing the best proxies for your Twitch viewer bots with extra network security!',
    h1: 'The most reliable Twitch Proxies'
  },
  mediaYoutube: {
    title: 'Best Youtube Proxies Provider – Youtube Residential Proxy IP | IPRocket',
    description:
      'Find the best Youtube proxy at IPRocket ! Accurately positioned and pure overseas real residential IP proxy service, obtain the latest business data, and purchase the fastest Youtube proxy！',
    h1: 'The most reliable Youtube Proxies'
  },
  airbnb: {
    title: 'Best Airbnb Proxies Provider – Airbnb Residential Proxy IP | IPRocket',
    description:
      'Accurately positioned and pure overseas real residential IP proxy service, obtain the latest business data, and purchase the fastest Airbnb proxy！',
    h1: 'The most reliable Airbnb Proxies'
  },
  amazon: {
    title: 'Best Amazon Proxies Provider – Amazon Residential Proxy IP | IPRocket',
    description:
      'A proxy server will help prevent a ban by changing your IP address. Scrap Amazon data and keep your real IP safe using IPRocket proxy server ! Learn more NOW!',
    h1: 'The most reliable Amazon Proxies'
  },
  eBey: {
    title: 'Discover The Best, Working eBay Proxy For Safe Browsing',
    description:
      'Ever had the thought how to create unlimited eBay accounts? With eBay proxy you can control, create and browse unlimited times without a trace!',
    h1: 'The most reliable eBey Proxies'
  },
  etsy: {
    title: 'Best Etsy Proxies Provider – Etsy Residential Proxy IP | IPRocket',
    description:
      'Manage many accounts, circumventing blocks and many more possibilities when using Etsy with IPRocket proxies ! Find out more at iprocket.io.',
    h1: 'The most reliable Etsy Proxies'
  },
  mercari: {
    title: 'Mercari Proxy — secure & cheap deals at IPRocket',
    description:
      'IPRocket is one of the best Mercari proxy servers providers! With proxies you can buy several second-hand goods from Mercari without being detected.',
    h1: 'The most reliable Mercari Proxies'
  },
  shopify: {
    title: 'Best Shopify Proxies Provider – Shopify Residential Proxy IP | IPRocket',
    description:
      'IPRocket is one of the best proxy service provider for Shopify ! Read more about proxy types. use cases in our site and star using it NOW!',
    h1: 'The most reliable Shopify Proxies'
  },
  vinted: {
    title: 'Best Vinted Proxies Provider – Vinted Residential Proxy IP | IPRocket',
    description:
      'Vinted may block an account due to certain reasons and when making payments online via credit or debit cards there is always the risk of fraud. Using IPRocket proxy can help protect against this.',
    h1: 'The most reliable Vinted Proxies'
  },
  walmart: {
    title: 'Best Walmart Proxies Provider – Walmart Residential Proxy IP | IPRocket',
    description:
      'Faster shopping, multiple accounts and online identity protection when using IPRocket proxies ! Find out more about Walmart proxy servers at iprocket.io.',
    h1: 'The most reliable Walmart Proxies'
  },
  countryMore: {
    title: 'See all the countries and regions we support',
    description:
      'With carefully tailored packages for enterprise and personal users, we guarantee a perfect solution for all your needs. You can choose specific continents, regions, and even cities that suit your needs.',
    h1: 'All Locations'
  },
  countryBrazil: {
    title: 'Buy Brazilian proxy server, more than 1.1 million IPs service',
    description:
      'Buy Brazilian Proxy at IPRocket. Check our country-wise proxy availability and helps you target global markets with precision.',
    h1: 'The most reliable Brazilian proxies'
  },
  countryVietnam: {
    title: 'Buy Vietnam proxy server, more than 600, 000  IPs service',
    description:
      'Buy Vietnam Proxy at IPRocket. Check our country-wise proxy availability and helps you target global markets with precision.',
    h1: 'The most reliable Vietnam proxies'
  },
  countryJapan: {
    title: 'Buy Japanese proxy server, more than 400, 000  IPs service',
    description:
      'Buy Japanese Proxy at IPRocket. Check our country-wise proxy availability and helps you target global markets with precision.',
    h1: 'The most reliable Japanese proxies'
  },
  countryGermany: {
    title: 'Buy German proxy server, more than 700, 000 IPs service',
    description:
      'Buy German Proxy at IPRocket. Check our country-wise proxy availability and helps you target global markets with precision.',
    h1: 'The most reliable German proxies'
  },
  countryCanada: {
    title: 'Buy Canadian proxy server, more than 500, 000 IPs service',
    description:
      'Buy Canadian Proxy at IPRocket. Check our country-wise proxy availability and helps you target global markets with precision.',
    h1: 'The most reliable Canadian proxies'
  },
  countryPhilippines: {
    title: 'Buy Philippine proxy server, more than 600, 000  IPs service',
    description:
      'Buy Philippine Proxy at IPRocket. Check our country-wise proxy availability and helps you target global markets with precision.',
    h1: 'The most reliable Philippine proxies'
  },
  countryHongkong: {
    title: 'Buy Hong Kong proxy server, more than 500, 000 IPs service',
    description:
      'Buy Hong Kong Proxy at IPRocket. Check our country-wise proxy availability and helps you target global markets with precision.',
    h1: 'The most reliable Hong Kong proxies'
  },
  countryBritain: {
    title: 'Buy British proxy server, more than 1.1 million IPs service',
    description:
      'Buy British Proxy at IPRocket. Check our country-wise proxy availability and helps you target global markets with precision.',
    h1: 'The most reliable British proxies'
  },
  countryAmerica: {
    title: 'Buy United States proxy server, more than 3 million IPs service',
    description:
      'Buy United States Proxy at IPRocket. Check our country-wise proxy availability and helps you target global markets with precision.',
    h1: 'The most reliable US proxies'
  },
  countryFrance: {
    title: 'Buy French proxy server, more than 1.1 million IPs service',
    description:
      'Buy French Proxy at IPRocket. Check our country-wise proxy availability and helps you target global markets with precision.',
    h1: 'The most reliable French proxies'
  },
  partnership: {
    title: 'IPRocket | Get Exclusive Partner Deals with IPRocket',
    description:
      'Discover IPRocket full list of partners, including anti-detection browsers, Cloudphone, accounts and virtual cards, and get special discounts on their services.',
    h1: "Welcome to be IPRocket's partners"
  }
};

export default MetaEnglish;
